import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchModelPage } from '../pages/ModelsOpen/search-model/search-model.page';
import { ModalController } from '@ionic/angular';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { DeliveryInformation } from '../Models/delivery.model';
import { Storage } from '@ionic/storage';
import { Category } from '../Models/homeData.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  Url = 'https://api.buy2go.us/api/';
  usertoken;
  constructor(
    private _httpclient: HttpClient,
    private auth: AuthService,
    private authService: AuthService,
    private storage : Storage
  ) {
    this.auth.currentToken.subscribe((t) => {
      this.usertoken = t;
    });
  }
  getUserData() {
    return this._httpclient.get(`${this.Url}buyer/show`, {
      headers: new HttpHeaders().set(
        'Authorization',
        `${this.authService.getToken()}`
      ),
    });
  }

  getHomeData(lang) {
    return this._httpclient.get(`${this.Url}home`);
  }

  getChatMessages(orderId) {
    return this._httpclient.get(`${this.Url}orders/${orderId}/messages`);
  }

  sendChatMessage(orderId, data) {
    return this._httpclient.post(`${this.Url}orders/${orderId}/send-message`, data);
  }

  addSliderToCart(id) {
    return this._httpclient.get(`${this.Url}orderitmes/slider/${id}`);
  }

  userArrived(id) {
    return this._httpclient.get(`${this.Url}order/${id}/user/arrived`);
  }

  getAllCategory(lang) {
    return this._httpclient.get(`${this.Url}categories`);
  }

  getCategory(categorId, lang) {
    return this._httpclient.get(`${this.Url}tags?id=${categorId}`);
  }

  getSearchTags(){
    return this._httpclient.get(`${this.Url}tags/list`);
  }

  getPopularSearch(){
    return this._httpclient.get(`${this.Url}popular-searches`);
  }


  getTagProducts(tag){
    return this._httpclient.get(`${this.Url}products/tags?tag=${tag}`);
  }

  getUserAddresses(){
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("token")
    );

    // this.storage.get('userInfo').then((res) => {
    //    let userID = res['id'];
    // });
      let userID = localStorage.getItem('userID')
    return this._httpclient.get(`${this.Url}address?id=${userID}`, {headers:header});


  }

  saveUserAddress(data){
    return this._httpclient.post(`${this.Url}address`, data);
  }

  checkIfAddressAllowed(data){
    return this._httpclient.post(`${this.Url}address/check-if-allowed`, data);
  }

  sendEmailWhenAddressAllowed(data){
    return this._httpclient.post(`${this.Url}address/save-user-email`, data);
  }

  deleteUserAddress(id): Observable<any> {
    return this._httpclient.delete(`${this.Url}address/${id}`);
  }

  setDefaultAddress(addressId) {
    return this._httpclient.get(`${this.Url}address/${addressId}/set-default`);
  }

  getSuggestedItems(){
    return this._httpclient.get(`${this.Url}products/features`);
  }

  getSimilarProducts(id) {
    return this._httpclient.get(`${this.Url}products/${id}/similar`);
  }

  getDealList(){
    return this._httpclient.get(`${this.Url}sliders`);

  }

  getCategoryProducts(categorId) {
    return this._httpclient.get(`${this.Url}categories/${categorId}/products`);
  }

  getAllItems(pageNumber, lang) {
    return this._httpclient.get(
      `${this.Url}products?pagination=true?page=${pageNumber}`
    );
  }

  getMoreItems(pageNumber, lang) {
    return this._httpclient.get(
      `${this.Url}products?pagination=true?page=${pageNumber}`
    );
  }

  getSingelItem(newsId: string) {
    return this._httpclient.get(` ${this.Url}products/${newsId}`);
  }

  // getOrders(): Observable<any> {
  //   return this._httpclient.get(`${this.Url}user/orders`);
  // }

  getOrders(): Observable<any> {
    return this._httpclient.get(`${this.Url}buyer/orders`);
  }

  getFaqs(){
    let type = 'client'
    return this._httpclient.get(`${this.Url}faqs?type=${type}`);
  }

  getTerms() {
    return this._httpclient.get(`${this.Url}terms`);
  }

  postIssue(data){
    return this._httpclient.post(`${this.Url}support-tickets`, data);
  }

  getIssueListHistory(){
    return this._httpclient.get(`${this.Url}support-tickets`);
  }

  getIssueSubjects(){
    return this._httpclient.get(`${this.Url}issues`);
  }

  ordersAction(id, data): Observable<any> {
    return this._httpclient.post(`${this.Url}orders/${id}/action`, data);
  }

  deleteOrder(id): Observable<any> {
    return this._httpclient.get(`${this.Url}order/${id}/delete`);
  }

  search(searchWord): Observable<any> {
    return this._httpclient.get(`${this.Url}search?value=${searchWord}`);
  }

  updateUserInfo(data): Observable<any> {
    return this._httpclient.post(`${this.Url}buyer/update`, data);
  }

  editPhoto(data): Observable<any> {
    return this._httpclient.post(
      `${this.Url}buyer/change/profilepicture`,
      data
    );
  }

  changePassword(data): Observable<any> {
    return this._httpclient.post(`${this.Url}buyer/change/password`, data);
  }

  resetPassword(data): Observable<any> {
    return this._httpclient.post(`${this.Url}buyer/reset/password`, data);
  }

  // visaPayment(data, orderID): Observable<any> {
  //   console.log(orderID)
  //   return this._httpclient.post(`${this.Url}payment`, { heartlandToken: data, order_id: orderID });
  // }

  visaPayment(data): Observable<any> {
    return this._httpclient.post(`${this.Url}paydirect`, data);
  }

  getRefundedOrders(): Observable<any> {
    return this._httpclient.get(`${this.Url}buyer/refund`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postRefund(refundID, refundType) {
    return this._httpclient
      .post(`${this.Url}buyer/refund`, {
        method: refundType,
        refund_id: refundID,
      })
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        })
      );
  }

  postCreditCard(data, month) {
    const formData: FormData = new FormData();
    formData.append('cardNumber', data.cardNumber);
    formData.append('expMonth', month);
    formData.append('expYear', data.expYear);
    formData.append('cvn', data.cvn);
    formData.append('name', data.name);
    return this._httpclient.post(`${this.Url}creditcarddata`, formData).pipe(
      map((res) => {
        console.log(res);
        return res;
      })
    );
  }

  getCreditList(): Observable<any> {
    return this._httpclient.get(`${this.Url}creditcarddata`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteCredit(id): Observable<any> {
    return this._httpclient.delete(`${this.Url}creditcarddata/${id}`);
  }

  setDefaultPayment(creditId) {
    return this._httpclient.get(`${this.Url}creditcarddata/${creditId}/set-default`);
  }

  getDeliveryDetails(orderID) {
    return this._httpclient.get(`${this.Url}orders/${orderID}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  rateDriver(orderId, data) {
    return this._httpclient.post(`${this.Url}orders/${orderId}/rate-driver`, data);
  }

  // getDeliveryDetails(orderID) {
  //   return this._httpclient
  //     .get(`${this.Url}delivery/status?id=${orderID}`)
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }

  applyPromoCode(code, orderID): Observable<any> {
    return this._httpclient
      .post(`${this.Url}orders/discount`, { code: code, order_id: orderID })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // getCourses(){
  //  let url = 'http://habeeballahapi.tuneit.mobi/api/'
  //   return this._httpclient.get(`${url}courses`);
  // }
}
