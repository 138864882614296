import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import { ForegroundService } from '@ionic-native/foreground-service/ngx';

// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
//import { FCM } from '@ionic-native/fcm/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './services/translate-config.service';
import { ChartsModule } from 'ng2-charts';
// import 'chartjs-plugin-zoom';
import { LanguagePopoverPageModule } from './translate/language-popover/language-popover.module';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { MessagingService } from './services/messaging.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpRequestInterceptor } from './interceptors/htttp-loading.interceptor';
// import { firebaseConfig } from '../env';
// import { ForegroundService } from '@ionic-native/foreground-service/ngx';
// import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx'
// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';

// import { MessagingService } from './services/messaging.service';

// import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';

import { CheckInternetConnectivityService } from './services/check-internet-connectivity.service';
import { AuthLoadGuard } from './auth/auth-load-guard.service';
import { PolicyModelComponent } from './Components/policy-model/policy-model.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { Firebase } from '@ionic-native/firebase/ngx';
// import { FcmService } from './services/fcm.service';
import { Network } from '@ionic-native/network/ngx';
import { SharedModule } from './shared.module';
import { PopupService } from './services/popup.service';
import { RateDeliveryPopupComponent } from './pages/ModelsOpen/rate-delivery-popup/rate-delivery-popup.component';
import { MaterialModule } from './material.module';

import { IonicRatingComponentModule } from 'ionic-rating-component';

import { IdVerificationComponent } from './pages/ModelsOpen/id-verification/id-verification.component';
import { FacebookLoginProvider, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PolicyModelComponent,
    RateDeliveryPopupComponent,
    IdVerificationComponent,
  ],
  entryComponents: [
    PolicyModelComponent,
    RateDeliveryPopupComponent,
    IdVerificationComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    AngularFireAuthModule,
    BrowserModule,
    HttpClientModule,
    AngularFireMessagingModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'ios',
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    LanguagePopoverPageModule,
    AngularFirestoreModule, // For FireStore
    AngularFireStorageModule, // For Storage
    AngularFireAuthModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot(),
    BrowserAnimationsModule, // For Authentication

    SharedModule,
    MaterialModule,
    IonicRatingComponentModule,

  ],
  providers: [
    LocationAccuracy,
    Geolocation,
    MessagingService,
    AsyncPipe,
    StatusBar,
    PopupService,
    SplashScreen,
    CallNumber,
    TranslateConfigService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    CheckInternetConnectivityService,
    //FCM,
    // //  Firebase,
    //   FcmService,
    //  FirebaseMessaging
    // FCM
    FCM,
    AuthLoadGuard,
    Network,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '747717516148966'
            )
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '623587124420-ujb2hlfa0ju4l5bafnpoe6ubnd5f9ma2.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
