import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Observable, EMPTY, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, delay, take, tap, map, catchError, retry } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()

export class HttpRequestInterceptor implements HttpInterceptor {
    token: any;
    constructor(private authService: AuthService) {
       
    }

    // interceptor to send user's token in each api calling 

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService.getToken().then(token => { this.token = token });        
        const reqWithAuth = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })

        console.log(reqWithAuth)

        return next.handle(reqWithAuth).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(error)
            })
        )
    }








}