import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-id-verification',
  templateUrl: './id-verification.component.html',
  styleUrls: ['./id-verification.component.scss'],
})
export class IdVerificationComponent implements OnInit {

  selectedDate;
  firstName: string;
  lastName: string;
  maxDate: Date = new Date();

  constructor(
    public dialogRef: MatDialogRef<IdVerificationComponent>,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.maxDate.setFullYear(new Date().getFullYear() - 21)
  }


  submit() {
    const dateArr = new Date(this.selectedDate).toLocaleDateString().split('/');
    const date = (dateArr[1].length === 2 ? dateArr[1] : '0' + dateArr[1]) + '-' + (dateArr[0].length === 2 ? dateArr[0] : '0' + dateArr[0]) + '-' + dateArr[2];
    this.dialogRef.close({ selectedDate: date, firstName: this.firstName, lastName: this.lastName });
  }

  removePlus21() {
    this.dialogRef.close({ removePlus21: true });
  }

  cancel() {
    this.dialogRef.close({ cancel: true });
  }

}
