import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { DirectionService } from './direction.service';

const LAG_KEY = 'SELECTED_LANGUAGE';
@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  selected = '';
  instant: any;
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private directionService:DirectionService

  ) { }

  getDefaultLanguage() {
    let language = this.translate.getBrowserLang();
    
    this.translate.setDefaultLang(language);
    
    this.directionService.changeDirection(language)

    this.storage.get(LAG_KEY).then(val => {
      if (val) {
        this.setLanguage(val);
        this.selected = val;
      }
      this.directionService.changeDirection(val)
    });
  }

  getLanguages() {
    return [
      { text: 'العربية', value: 'ar', img: '../assets/flags/arFlag.png' },
      { text: 'English', value: 'en', img: '../assets/flags/enFlag.png' },
      { text: 'French', value: 'fe', img: '../assets/flags/frFlag.png' }
    ];
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
    this.selected = setLang;
    this.storage.set(LAG_KEY, setLang)
  }

  getlangFromStorage(){
    return this.selected
  }

}