import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-policy-model',
  templateUrl: './policy-model.component.html',
  styleUrls: ['./policy-model.component.scss'],
})
export class PolicyModelComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }
    
  ngOnInit() {}


  //close modal
  onCancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

}
