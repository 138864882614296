import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { DirectionService } from 'src/app/services/direction.service';
import { ShareEventsService } from 'src/app/services/share-events.service';

@Component({
  selector: 'app-language-popover',
  templateUrl: './language-popover.page.html',
  styleUrls: ['./language-popover.page.scss'],
})
export class LanguagePopoverPage implements OnInit {
languages=[];
selected ='';

  constructor( private popoverCtrl :PopoverController,
    private languageService:TranslateConfigService
    ,private setDirectionService:DirectionService,
    private sharedService:ShareEventsService
    ) { }

  ngOnInit() {
    this.languages = this.languageService.getLanguages();
    this.selected = this.languageService.selected;
  }

  select(lan){
      this.setDirectionService.changeDirection(lan)
    this.languageService.setLanguage(lan);
    this.popoverCtrl.dismiss();
    this.sharedService.sendClickEvent();
  }

 
}
