import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Item } from 'src/app/Models/homeData.model';
import { Storage } from '@ionic/storage';

export interface Product {
  id: number;
  name: string;
  price: number;
  amount: number;
}
@Injectable({
  providedIn: 'root',
})
export class CartServiceService {
  data: Item[] = [];

  private cart = [];
  private cartItemCount = new BehaviorSubject(0);

  constructor(private storage: Storage) {
    console.log('Ssss');
  }

  getProducts() {
    return this.data;
  }

  getCart() {
    return this.cart;
  }

  getCartItemCount() {
    // this.resitCarCounter();
    // console.log("this.cartItemCount.value", this.cartItemCount.value);
    // if (this.cartItemCount.value < 0) this.cartItemCount.next(0);
    return this.cartItemCount;
  }

  async resitCarCounter() {
    this.cart = [];
    await this.cartItemCount.next(0);
    this.setCounterInLocalStorage();
  }

  intiCarCounter(count) {
    this.cartItemCount.next(this.cartItemCount.value + count);
  }

  addProduct(product) {
    // console.log('addProduct')
    // let added = false;
    // for (let p of this.cart) {
    //   if (p.id === product.id) {
    //     added = true;
    //     break;
    //   }
    // }

    // console.log("added", added)
    // if (!added) {
    //   this.cart.push(product);
    //   console.log(this.cartItemCount.value);
    //   this.cartItemCount.next(this.cartItemCount.value + 1);
    //   console.log(this.cartItemCount.value);

    //   this.setCounterInLocalStorage();
    // }

    this.cart.push(product);
    console.log(this.cartItemCount.value);
    this.cartItemCount.next(this.cartItemCount.value + 1);
    console.log(this.cartItemCount.value);

    this.setCounterInLocalStorage();
    let count : number;
    if (Number(localStorage.getItem('cartCount'))) {
      count = Number(localStorage.getItem('cartCount'));
    } else {
      count = 0;
    }

    count += 1;
    localStorage.setItem('cartCount', String(count));
  }

  decreaseProduct(product) {
    // for (let [index, p] of this.cart.entries()) {
    //   if (p.id === product.id) {
    //     p.amount -= 1;
    //     if (p.amount == 0) {
    //       this.cart.splice(index, 1);
    //     }
    //   }
    // }
    // this.cartItemCount.next(this.cartItemCount.value - 1);

    // let p  = this.cart.find(cart => cart.id == product.product.id);
    // console.log(p)
    // if(!p) return;

    console.log('this.cartItemCount.value => ');
    console.log('this.cartItemCount.value => ' + this.cartItemCount.value);
    // let index = this.cart.indexOf(p)
    // this.cart.splice(index, 1);

    this.cartItemCount.next(this.cartItemCount.value - 1);

    this.setCounterInLocalStorage();
  }

  removeProduct(product, decrease = false) {
    console.log(this.cart);
    // for (let [index, p] of this.cart.entries()) {

    //   if (p.id === product.id) {
    //     this.cartItemCount.next(this.cartItemCount.value - p.amount);
    //     this.cart.splice(index, 1);
    //   }
    // }
    console.log(product);

    // console.log(this.cart)
    // let p  = this.cart.find(cart => cart.id == product.product.id);
    // console.log(p)
    // if(!p) return;

    console.log('this.cartItemCount.value => ');
    console.log('this.cartItemCount.value => ' + this.cartItemCount.value , localStorage.getItem('cartCount'));
    //let index = this.cart.indexOf(p)
    //this.cart.splice(index, 1);
    let count ;
    count = Number(localStorage.getItem('cartCount'));
    count -= decrease ? 1 : Number(product?.quantity);
    localStorage.setItem('cartCount', String(count));
    this.cartItemCount.next(this.cartItemCount.value - (decrease ? 1 : Number(product?.quantity)));

    this.setCounterInLocalStorage();
  }

  setCounterInLocalStorage() {
    if (this.cartItemCount.value < 0) this.cartItemCount.next(0);
    this.storage.set('cartItemsCount', this.cartItemCount.value);
  }
}
