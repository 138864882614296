import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _httpclient: HttpClient, private storage : Storage) { }

  login(loginData) {
    this.storage.clear()
    return this._httpclient.post(`https://api.buy2go.us/api/login`, loginData)
  }

  register(registerData) {
    return this._httpclient.post(`https://api.buy2go.us/api/register`, registerData)
  }

}
