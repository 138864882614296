import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { RateDeliveryPopupComponent } from '../pages/ModelsOpen/rate-delivery-popup/rate-delivery-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { IdVerificationComponent } from '../pages/ModelsOpen/id-verification/id-verification.component';
import { DriverModel } from '../Models/delivery.model';
import { DataService } from './data.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(
    private actionSheetController: ActionSheetController,
    private dialog: MatDialog,
    private alertController : AlertController,
    private dataService: DataService,
    private callNumber: CallNumber,
    private router: Router
  ) {}

  callDriverPopup(phone) {
    this.actionSheetController
      .create({
        header: 'Call Driver',
        subHeader: 'Are You Sure You Want To Call The Driver?!',
        cssClass: 'my-custom-class',
        backdropDismiss: false,

        buttons: [
          {
            text: 'Yes',
            handler: () => {
              this.callNumber
              .callNumber(phone, true)
              .then((res) => {});
            },
          },
          {
            text: 'No',
            handler: () => {
              console.log('Share clicked');
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  somethingWrongPopup(errorMsg) {
    this.actionSheetController
      .create({
        header: 'Something Went Wrong',
        subHeader: errorMsg,
        cssClass: 'something-wrong-popup',
        backdropDismiss: false,

        buttons: [
          {
            text: 'Try Again',
            handler: () => {
              return true;
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  rateDeliveryPopup(orderId: string, driver: DriverModel, subject?: Subject<void>): void {

    const dialogRef = this.dialog.open(RateDeliveryPopupComponent, {
      minHeight: '350px',
      panelClass: 'dialog-class',
      data: { orderId: orderId, driver: driver }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(subject) {
        subject.next();
      }
    });
  }

  /*success popup will be used for any success msg like (password changed successfully)
   so u have to pass to this function (title, sub-title,btnText) by the way
   after the intialization of this popup u don't need to do any process
   the button will close the popup not anymore
    */
  successPopup(title, subTitle, btnText) {
    this.actionSheetController
      .create({
        header: title,
        subHeader: subTitle,
        cssClass: 'success-popup',
        backdropDismiss: false,

        buttons: [
          {
            text: btnText,
            handler: () => {
              return true;
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  weAreSorryPopup(data) {
    this.actionSheetController
      .create({
        header: 'We Are Sorry',
        subHeader: `We Don't Deliver To Your Area Yet, Do You Like To Receive And Email If We Start Delivering To Your Area?`,
        cssClass: 'my-custom-class',
        backdropDismiss: false,
        buttons: [

          {
            text: 'Yes',
            handler: () => {
              this.dataService.sendEmailWhenAddressAllowed(data).subscribe(() => {
                this.successPopup('Thank You', 'You will recieve an email once we start delivering to your area', 'Continue');
              }, error => {
                this.somethingWrongPopup(error.error['error']);
              })
            },
          },
          {
            text: 'No',
            handler: () => {
              console.log('Share clicked');

            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }



 async emailPopup(data){
    const alert = await this.alertController.create({
        header: "Please Enter Your Email",
        backdropDismiss: false,
        cssClass: 'email-alert-popup',
        inputs: [
          {
            cssClass: 'email-inp-popup',
            placeholder : 'Email',
            name: 'email',
            type: 'text'
          }],
         buttons: [
             {
                text: 'Continue',
                cssClass: 'email-btn-popup',
                handler: (alertData) => { //takes the data
                  console.log(alertData.name1);
                  data.email = alertData.email;

              }
              }
            ]
    });
    await alert.present();
  }


  confirmDeletionPopup(header, subHeader, subjectToNextWithResult: Subject<boolean>) {
    this.actionSheetController
      .create({
        header: header,
        subHeader: subHeader,
        cssClass: 'delete-action-sheet',
        backdropDismiss: false,

        buttons: [

          {
            text: 'Yes',
            handler: () => {
              subjectToNextWithResult.next(true);
            },
          },
          {
            text: 'No',
            handler: () => {
              console.log('Share clicked');
              subjectToNextWithResult.next(false);
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  youMustLoginFirstPopup() {
    this.actionSheetController
      .create({
        header: 'You must login first',
        subHeader: 'Go to login page ?',
        cssClass: 'delete-action-sheet',
        backdropDismiss: false,

        buttons: [

          {
            text: 'Ok',
            handler: () => {
              this.router.navigate(['/auth']);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              console.log('Share clicked');
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }


  idVerificationPopup(): Observable<any> {
    let suggestedItem = null;

    const dialogRef = this.dialog.open(IdVerificationComponent, {
      minHeight: '350px',
      panelClass: 'verification-dialog',
      data: { item: suggestedItem },
    });

    return dialogRef.afterClosed();
  }
}
