import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserAddress } from 'src/app/pages/change-location/change-location.page';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() menuOrBack: string;
  @Input() dropListTitle: boolean = false;
  @Input() isSearchPage: boolean = false;
  @Input() toggleListOpening: Subject<boolean>;
  @Input() hideSearch: boolean;
  @Input() showAddress: boolean;
  @Input() address: {
    address: UserAddress;
    time: string;
  }
  @Input() searchSubject: Subject<string>;
  @Input() searchWord: string;
  isGuest: boolean;
  listIsOpened: boolean;

  constructor(public location: Location, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('token')) this.isGuest = false;
    else this.isGuest = true;
  }

  get isBack(): boolean {
    return this.menuOrBack === 'back';
  }

  toggleListOpen() {
    if(!this.dropListTitle) return;
    this.listIsOpened = !this.listIsOpened;
    this.toggleListOpening.next(this.listIsOpened)
  }

  goToSearchPage() {
    this.router.navigate(['pages/search-model'])
  }

  goToAddressesPage() {
    this.router.navigate(['/pages/tabs/address-list'])
  }

  searchInputonKey(event: any, searchValue: string) {
    console.log(searchValue)
    this.searchSubject.next(searchValue);
  }
}
