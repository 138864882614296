import { CartServiceService } from './services/cart-service.service';
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Storage } from '@ionic/storage';
import {
  AlertController,
  IonRouterOutlet,
  Platform,
  ToastController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './auth/auth.service';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { TranslateConfigService } from './services/translate-config.service';
import { CartService } from './services/cart.service';
import { MessagingService } from './services/messaging.service';
import { Location, PlatformLocation } from '@angular/common';
import { CheckInternetConnectivityService } from './services/check-internet-connectivity.service';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Network } from '@ionic-native/network/ngx';
import { DataService } from './services/data.service';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  menuDirection = 'start';
  title = 'push-notification';
  message;
  show;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  navigate: any;
  imagePreview: string = 'assets/imgs/profile-img.png';
  userInfo: {
    name: string;
    phone: string;
    image: string;
  };
  alertBackPresented: boolean;

  constructor(
    private messagingService: MessagingService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private cartService: CartService,
    private cartService2: CartServiceService,
    private storage: Storage,
    private alertController: AlertController,
    private location: Location,
    private connectivityProvider: CheckInternetConnectivityService,
    private fcm: FCM,
    private network: Network,
    private toastController: ToastController,
    private dataService: DataService,
    private notificationService: NotificationsService,
    private platformLocation: PlatformLocation
  ) {
    this.initializeApp();
  }
  ngOnInit() {
    this.storage.get('cartItemsCount').then((val) => {
      this.cartService.changeCount(+val);
      this.cartService2.intiCarCounter(+val);
    });

    this.router.events.subscribe((e) => {
      if (
        e instanceof ActivationStart &&
        e.snapshot.outlet === 'administration'
      )
        this.outlet.deactivate();
    });
    this.sideMenu();
    this.authService.updateUserDetails.subscribe(() => {
      this.getUserData();
    })
    this.getUserData();
  }
  sideMenu() {
    this.navigate = [
      {
        title: 'Home',
        url: '/curbside/pages/tabs/home',
        icon: 'apps',
      },
    ];
  }

  //listenConnection
  private listenConnection(): void {
    this.network.onDisconnect().subscribe(() => {
      this.presentToast('Please check your interent connection', 'danger');
    });
  }

  //initializeApp
  initializeApp() {
    this.platform.ready().then(() => {
      this.backButtonEvent();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.translateConfigService.getDefaultLanguage();
      this.listenConnection();
      this.getNotificationPermission();
    });
  }

  /* logout fun */

  logout() {
    this.authService.logout();
    localStorage.removeItem('token');
    this.router.navigateByUrl('/auth');
  }

  async presentToast(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: color,
      position: 'top',
    });
    toast.present();
  }

  //backButtonEvent
  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if ((this.router.url === '/pages/tabs/home' && this.authService.isLoggedIn()) || this.router.url === '/auth') {
          this.presentAlertConfirm();
          this.platformLocation.pushState(null, null, this.platformLocation.pathname);
          return;
        } else if (this.router.url.includes('tracking-order')) {
          this.platformLocation.pushState(null, null, this.platformLocation.pathname);
          return;
        }
        this.location.back();

        // if (this.router.url != '/pages/tabs/home') {
        //   // await this.router.navigate(['/']);
        //   await this.location.back();
        // } else if (this.router.url === '/pages/tabs/home') {
        //   if (
        //     new Date().getTime() - this.lastTimeBackPress >=
        //     this.timePeriodToExit
        //   ) {
        //     this.lastTimeBackPress = new Date().getTime();
        //     this.presentAlertConfirm();
        //   } else {
        //     navigator['app'].exitApp();
        //   }
        // }
      });
    });
  }
  //presentAlertConfirm
  async presentAlertConfirm() {
    if(this.alertBackPresented) return;
    this.alertBackPresented = true;
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.alertBackPresented = false;
          },
        },
        {
          text: 'Close App',
          handler: () => {
            this.alertBackPresented = false;
            navigator['app'].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  //getUserData
  async getUserData() {
    await this.dataService.getUserData().subscribe((res) => {
      this.userInfo = res['data'];
      if (!res['data'].image) this.userInfo.image = this.imagePreview;
    });
  }

  getNotificationPermission() {
    if (this.platform.is('cordova')) {
      if (this.platform.is('ios')) {
        this.fcm.requestPushPermission().then((res) => {
          if (res) {
            localStorage.setItem('pushNotification', 'true');

            this.fcm.getToken().then((res) => {
              console.log('Token : ', res);
              this.authService.updateFcmToken(res);
            });
            this.fcm.onTokenRefresh().subscribe((res) => {
              console.log('Token refresh: ', res);
              this.authService.updateFcmToken(res);
            });

            this.fcm.onNotification().subscribe((res) => {
              console.log(res);
              if(res.wasTapped) {
                if (res.params) {
                  this.router.navigate([res.url, JSON.parse(res.params)]);
                } else {
                  this.router.navigate([res.url]);
                }
              }
              this.notificationService.notificationRecieved.next(res);
            });
          }
        });
      } else if (this.platform.is('android')) {
        this.fcm.getToken().then((token) => {
          console.log('Token : ', token);
          this.authService.updateFcmToken(token);
        });
        this.fcm.onTokenRefresh().subscribe((res) => {
          this.authService.updateFcmToken(res);
        });
        this.fcm.getInitialPushPayload().then((res) => {
          console.log('Recieve notificatioon getInitialPushPayload', res);
          if(res.wasTapped) {
            if (res.params) {
              this.router.navigate([res.url, JSON.parse(res.params)]);
            } else {
              this.router.navigate([res.url]);
            }
          }
          this.notificationService.notificationRecieved.next(res);
         },error => {
          console.log('Recieve notificatioon error getInitialPushPayload', error);
        });
        this.fcm.onNotification().subscribe((res) => {
          console.log('Recieve notificatioon ', res);
          if(res.wasTapped) {
            if (res.params) {
              this.router.navigate([res.url, JSON.parse(res.params)]);
            } else {
              this.router.navigate([res.url]);
            }
          }
          this.notificationService.notificationRecieved.next(res);
        },error => {
          console.log('Recieve notificatioon error', error);
        });
      }
    } else {
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
      this.show = this.messagingService.currentMessage;
    }
  }
}
