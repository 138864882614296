import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  Url = 'https://api.buy2go.us/api/';


  public notifiCountSource = new BehaviorSubject(0);
  notifiCurrentCount = this.notifiCountSource.asObservable();

  public notifiNumSource = new BehaviorSubject(0);
  notifiCurrentNum = this.notifiNumSource.asObservable();
  notificationRecieved = new Subject<any>();


  constructor(
    private _httpclient: HttpClient,
    public toastController: ToastController,
    private router: Router

  ) {

  }



  getUserNotification(requestOptions) {
    return this._httpclient.get(`${this.Url}notifications`, requestOptions);
  }

  c() {
    return this._httpclient.get(`${this.Url}buyer/notification`);
  }

  setReadNotification(id, requestOptions) {
    return this._httpclient.get(`${this.Url}notifications/${id}/read/`, requestOptions);
  }
  setReadAllNotification(id, requestOptions) {
    return this._httpclient.get(`${this.Url}notifications/${id}/readall/`, requestOptions);
  }

  getCountOfNotifications() {
    return this.notifiCurrentCount
  }

  deleteNotif(id){
    return this._httpclient.get(`${this.Url}notification/${id}/delete`);
  }

  changeNotifiCount(count: number) {
    this.notifiCountSource.next(this.notifiCountSource.value + count);
    console.log(this.notifiCountSource.value)

  }



  getNumOfNotifications() {
    return this.notifiCurrentNum
  }

  resetNotifiCount() {
    this.notifiCountSource.next(0);
  }


  changeNotifiNum(id: number) {
    this.notifiNumSource.next(id);
  }

  async presentToast(data) {

    const toast = await this.toastController.create({
      message: data.message,
      duration: 5000,
      color: "light",
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'notifications',
          text: '',
          handler: () => {
            if (data.params) {
              this.router.navigate([data.url, JSON.parse(data.params)]);
            } else {
              this.router.navigate([data.url]);
            }
            }
        }, {
          text: 'Open',
          role: 'cancel',
          handler: () => {
            if (data.params) {
              this.router.navigate([data.url, JSON.parse(data.params)]);
            } else {
              this.router.navigate([data.url]);
            }
          }
        }
      ]
    });
    setTimeout(() => {
      toast.present();

    }, 1500);

  }
}
