import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-rate-delivery-popup',
  templateUrl: './rate-delivery-popup.component.html',
  styleUrls: ['./rate-delivery-popup.component.scss'],
})
export class RateDeliveryPopupComponent implements OnInit {
  mode: MODE;
  tips: number;
  rate: number;
  constructor(
    public dialogRef: MatDialogRef<RateDeliveryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) {
    console.log('this.data',this.data);
  }

  ngOnInit() {
    this.mode = MODE.FIRST_MODE;
  }


  onRatingChange(rating) {
    console.log('The evaluation was modified and now its value is: ', rating);
    this.rate = rating;
    // do your stuff
  }

  enterTipManually() {
    this.mode = MODE.SECOND_MODE;
    this.tips = undefined;
  }

  dismissPopup() {
    this.dialogRef.close();
  }

  submit() {
    this.mode = MODE.THIRD_MODE;
    this.dataService.rateDriver(this.data.orderId, { rate: this.rate, tip_amount: this.tips}).subscribe();
  }
}

enum MODE {
  FIRST_MODE,
  SECOND_MODE,
  THIRD_MODE
}
