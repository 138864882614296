import { CartServiceService } from 'src/app/services/cart-service.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { Storage } from '@ionic/storage';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth/auth';
import { AngularFirestore } from '@angular/fire/firestore/firestore';
import { Router } from '@angular/router';
import { NotificationsService } from '../services/notifications.service';
import { Platform } from '@ionic/angular';


interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;

  fcmTokens?: { [token: string]: true };
}



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<User>;
  updateUserDetails: Subject<void> = new Subject<void>();

  private _userIsAuthenticated = false;

  get userIsAuthenticated() {
    return this._userIsAuthenticated = true;
  }

  private token = '';

  private tokenSource = new BehaviorSubject('');
  currentToken = this.tokenSource.asObservable();

  constructor(private storage: Storage, private loginService: LoginService,
    private _httpclient: HttpClient,
    private cartService: CartServiceService,
    private notifiService: NotificationsService,
    private platform: Platform
  ) {
    storage.get('token').then((val) => {
      if (val) {
        this._userIsAuthenticated = true
      } else {
        this._userIsAuthenticated = false
      }
    });

  }


  // Save user token to be able call any api
  setToken(token: string) {
    this.tokenSource.next(token)
  }

  // set user token

  usertoken(token) {
    return this.token = token;
  }


  //get user token
  getusertoken() {
    return this.token
  }

  // login service function

  login(data) {
    return this.loginService.login(data)
  }

  //signup service function

  register(data) {
    return this.loginService.register(data)
  }

  getToken() {
    return this.storage.get('token').then((val) => {
      return JSON.parse(val);
    });
  }


  //check user authentication

  isLogged() {
    return this._userIsAuthenticated;
  }


  // Logout function and clear the whole cache

  logout() {
    this._userIsAuthenticated = false;
    this.storage.clear();
    this.notifiService.resetNotifiCount();
    this.cartService.resitCarCounter()
   // let cartCount = localStorage.getItem('cartCount');
    localStorage.clear()
    //localStorage.setItem('cartCount', cartCount)
    this.storage.get('token').then(val => { })
  }

  // Logout function and clear the whole cache

  isLoggedIn() : boolean{
    if(localStorage.getItem('token') && localStorage.getItem('mail_verified')) return true;
    else return false
  }

  updateFcmToken(token: string) {
    this.storage.set('notification_token', token);
    localStorage.setItem('notification_token', token);
    this._httpclient.post(`https://api.buy2go.us/api/fcm/token/update`,
    {fcm_token: token, fcm_type: document.URL.startsWith('http://localhost:8100') ? 'web' : (this.platform.is('android') ? 'android' : (this.platform.is('ios') ? 'ios' : 'web'))})
    .subscribe();
  }

}
