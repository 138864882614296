import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from "@ionic/storage";
import { AuthService } from '../auth/auth.service';
import { Observable, BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CartService implements OnInit {
  Url = 'https://api.buy2go.us/api/';
  headeroption: any
  counter: [];
  counterArrayId = [];
  uniques;
  curent = 0
  private countSource = new BehaviorSubject(0);
  currentCount = this.countSource.asObservable();

  constructor(private _httpclient: HttpClient, private storage: Storage, private authService: AuthService) {
  //  this.storage.set('cartItemsCount', 0)
  }
  ngOnInit() {
  }

  setItemToCart(id, quantity, headeroption,cost): Observable<any> {

    this.curent = (+this.storage.get('cartItemsCount'))
    this.changeCount(this.curent + 1)
    this.counterArrayId.push(id)
    this.uniques = [...new Set(this.counterArrayId)];
    // this.changeCount(+this.uniques.length)

    // &quantity=${quantity}

    return this._httpclient.post(`${this.Url}orderitmes?id=${id}&cost=${cost}`, {}, headeroption)
  }

  decrementItemQuantity(order_id, headeroption) {
    return this._httpclient.post(`${this.Url}orderitmes/${order_id}/decrement-quantity`, {}, headeroption);
  }

  decreesCounter() {
  }

  changeCount(count: number) {
    this.countSource.next(count)
  }

  myItem(): Observable<any> {
    console.log("Sssssssssssssss")
    return this._httpclient.get(`${this.Url}orderitmes`);
  }

  deleltMyItem(order_id, headeroption) {

    return this._httpclient.delete(`${this.Url}orderitmes/${order_id}`, headeroption);
  }

  replaceMyItem(orderItemId, productId, headeroption) {

    return this._httpclient.post(`${this.Url}orderitmes/${orderItemId}/replace`, {id: productId}, headeroption);
  }

  getStores(): Observable<any> {
    return this._httpclient.get(`${this.Url}stores`);
  }

  getPayment(): Observable<any> {
    return this._httpclient.get(`${this.Url}payments`);
  }

  sendOrder(data): Observable<any> {
    return this._httpclient.post(`${this.Url}orders`, data);
  }

  editOrder(id, data, headeroption): Observable<any> {
    return this._httpclient.put(`${this.Url}orders/${id}`, data, headeroption);
  }

  orderAgain(orderId): Observable<any> {
    return this._httpclient.post(`${this.Url}orderitmes/order-again`, {order_id: orderId});
  }

  editItemQuantity(id, quantity, headeroption): Observable<any> {
    return this._httpclient.post(`${this.Url}orderitmes/${id}/update`, {quantity : quantity}, headeroption);
  }

  payByWallet(orderID):Observable<any>{
    return this._httpclient.post(`${this.Url}payment/wallet`, {order_id: orderID})
  }

  editPaymentMethod(dataModel):Observable<any>{
    return this._httpclient.post(`${this.Url}payment/method`, dataModel)
  }

  applyPromoCode(code, headeroption): Observable<any> {
    return this._httpclient.post(`${this.Url}orders/apply-promo-on-cart`, {code: code}, headeroption);
  }

  loadCheckoutDetails(promocode) {
    return this._httpclient.post(`${this.Url}orders/checkout-details`, {code: promocode});
  }

  removePlus21Items(promocode) {
    return this._httpclient.post(`${this.Url}orderitmes/remove-adult-items`, {code: promocode});
  }

}
