import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable, fromEvent, merge, of} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CheckInternetConnectivityService {

  


    public appIsOnline$: Observable<boolean>;

    constructor(private toastController : ToastController) {
  
      this.initConnectivityMonitoring();
    this.appIsOnline$.subscribe();
    }
  
    public initConnectivityMonitoring() {
  
      if (!window || !navigator || !('onLine' in navigator)) return;


      
  
      this.appIsOnline$ = merge(
        of(null),
        fromEvent(window, 'online'),
        fromEvent(window, 'offline')
      ).pipe(map((res => {
          console.log(navigator.onLine)
          if(navigator.onLine == false){
              this.presentToast();
          }
          return navigator.onLine
      })))
  
    }

    async presentToast() {
        const toast = await this.toastController.create({
          message: 'Please check your internet connection',
          duration: 5000,
          color: 'danger',
          position: 'top',
        });
        toast.present();
      }
 

  
}
