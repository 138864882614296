import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {
  constructor( private platform:Platform) { }

  changeDirection(lan){
    if (lan === 'ar'){
      document.documentElement.dir = "ltr";
    }else {
      document.documentElement.dir = "ltr";
    }
    
  }
  
}
