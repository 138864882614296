import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs'
import { AuthService } from '../auth/auth.service';
import { NotificationsService } from './notifications.service';


@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  notificationFlag = new BehaviorSubject(0);
  notificationToken = new BehaviorSubject(null);


  constructor(private angularFireMessaging: AngularFireMessaging, private authService: AuthService, private router: Router, private notificationService: NotificationsService) {
    console.log('inside cons')
    // this.angularFireMessaging.messages.subscribe(
    //   (msgings: any) => {
    //     console.log(msgings)
    //      msgings.onMessage = msgings.onMessage.bind(msgings);
    //     msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
    //   });

    // this.requestPermission()
  }

  requestPermission() {
    console.log('inside request permission')
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token)
        // localStorage.setItem('sssss', 'ssss')
        this.authService.updateFcmToken(token);
        this.notificationToken.next(token)
      });
  }

  receiveMessage() {
     // this subscription reads notification payload when the app is opened by clicking a notification
    navigator.serviceWorker.addEventListener('message', event => {
      console.log('serviceWorker msg', event);
      if(event.data.messageType === 'notification-clicked') {
        if (event.data.data.params) {
          this.router.navigate([event.data.data.url, JSON.parse(event.data.data.params)]);
        } else {
          this.router.navigate([event.data.data.url]);
        }
      }
      event.data.data.messageType = event.data.messageType;
      this.notificationService.notificationRecieved.next(event.data.data);
    });
  }


}
