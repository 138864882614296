import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule) },
  { path: 'choose-store', loadChildren: () => import('./choose-store/choose-store.module').then( m => m.ChooseStorePageModule)},
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesPageModule) },
  { path: '**', loadChildren: () => import('./pages/pages.module').then(m => m.PagesPageModule) },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),

  ],
  exports: [RouterModule]

})
export class AppRoutingModule { }
